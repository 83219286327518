import styles from './certificates.module.css'
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { Certificate } from '../../components/certificate/Certificate';

import melamineHuaqiangPreview from '../../images/melamine-huaqiang-preview.jpg';

import pfaKylinPreview from '../../images/pfa-kylin-preview.jpg';

import trgEaPreview from '../../images/trg-ea-preview.jpg';

import melamineHuaqiang from '../../documents/certificates/melamine-huaqiang-certificate.pdf';
import pfaKylin05 from '../../documents/certificates/pfa-kylin-certificate-201140805.pdf';
import pfaKylin06 from '../../documents/certificates/pfa-kylin-certificate-201140806.pdf';
import pfaKylin07 from '../../documents/certificates/pfa-kylin-certificate-201140807.pdf';
import pfaKylin09 from '../../documents/certificates/pfa-kylin-certificate-201140809.pdf';
import trgEa from '../../documents/certificates/trg-ea-certificate.pdf';


import useVH from 'react-vh';
import { Seo } from '../../helpers/Seo';

export function Certificates() {
    useVH();
    return (
        <>
            <article className={styles.certificates}>
                <Breadcrumbs />
                <h1 className={styles.title}>Сертификаты</h1>
                <p className={styles.text}>Вся поставляемая продукция сертифицирована в соответствии с действующим
                    законодательством РФ.</p>
                <div className={styles.certificatesContainer}>
                    <Certificate
                        preview={melamineHuaqiangPreview}
                        name='HUAQIANG CHEMICAL GROUP CO., LTD'
                        file={melamineHuaqiang}
                    />
                    <Certificate
                        preview={pfaKylinPreview}
                        name='SHIFANG CHANGFENG CHEMICAL, 201140805'
                        file={pfaKylin05}
                    />
                    <Certificate
                        preview={pfaKylinPreview}
                        name='SHIFANG CHANGFENG CHEMICAL, 201140806'
                        file={pfaKylin06}
                    />
                    <Certificate
                        preview={pfaKylinPreview}
                        name='SHIFANG CHANGFENG CHEMICAL, 201140807'
                        file={pfaKylin07}
                    />
                    <Certificate
                        preview={pfaKylinPreview}
                        name='SHIFANG CHANGFENG CHEMICAL, 201140809'
                        file={pfaKylin09}
                    />
                    <Certificate
                        preview={trgEaPreview}
                        name='E&A (CHINA) UNION International Trade Co., LTD'
                        file={trgEa}
                    />
                </div>
            </article>
            <Seo
                title='Сертификаты'
                description='Вся поставляемая продукция сертифицирована в соответствии с действующим законодательством РФ.'
                keywords='антипирены, сертификат качества, сертифицированный меламин, сертифицированный полифосфат аммония, сертифицированный ТРГ'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}
