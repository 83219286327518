import styles from './product.module.css';
import polyphosphateImage from '../../images/product-polyphosphate.jpg';
import { Breadcrumbs } from '../../components/breadcrumbs/Breadcrumbs';
import { More } from '../../components/more/More';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { OrderForm } from '../../components/form/Form';
import { Seo } from '../../helpers/Seo';
import { Certificate } from '../../components/certificate/Certificate';
import pfaKylinPreview from '../../images/pfa-kylin-preview.jpg';
import pfaKylin05 from '../../documents/certificates/pfa-kylin-certificate-201140805.pdf';
import pfaKylin06 from '../../documents/certificates/pfa-kylin-certificate-201140806.pdf';
import pfaKylin07 from '../../documents/certificates/pfa-kylin-certificate-201140807.pdf';
import pfaKylin09 from '../../documents/certificates/pfa-kylin-certificate-201140809.pdf';

export function Polyphosphate() {
    return (
        <>
            <article className={styles.product}>
                <Breadcrumbs />
                <div className={styles.productContainer}>
                    <h1 className={styles.title}>Полифосфат аммония</h1>
                    <div className={styles.container}>
                        <img
                            className={styles.image}
                            src={polyphosphateImage}
                            alt="Полифосфат аммония в мешках"
                        />
                        <div className={styles.infoContainer}>
                            <div className={styles.infoTable}>
                                <div className={styles.infoTableRow}>
                                    <p className={styles.infoTableRowContent}>
                                        <span className={styles.bold}>Производитель:</span></p>
                                    <p className={styles.infoTableRowContent}>Китай</p>
                                </div>
                                <div className={styles.infoTableRow}>
                                    <p className={styles.infoTableRowContent}><span className={styles.bold}>Фасовка и комплектация:</span>
                                    </p>
                                    <p className={styles.infoTableRowContent}>25 кг (мешок)</p>
                                </div>
                                <p className={styles.summary}>Итоговая стоимость заказа согласовывается
                                    индивидуально</p>
                            </div>
                            <OrderForm product='Полифосфат аммония' />
                        </div>
                    </div>
                </div>
                <div className={styles.tabsOverlay}>
                    <Tabs
                        className={styles.tabsContainer}
                        selectedTabClassName={styles.active}
                        selectedTabPanelClassName={styles.content}
                    >
                        <TabList className={styles.tabs}>
                            <Tab className={styles.name}>О товаре</Tab>
                            <Tab className={styles.name}>Доставка и оплата</Tab>
                            <Tab className={styles.name}>Хранение</Tab>
                            <Tab className={styles.name}>Сертификация</Tab>
                        </TabList>

                        <TabPanel>
                            <div className={styles.text}>
                                <p>Товар поставляется в мешках фасовкой 25 кг.</p>
                                <p>Страна производитель – Китай.</p>
                                <p>
                            <span className={styles.bold}>
                                Полифосфат аммония
                            </span> – это
                                    неорганическая соль фосфорной
                                    кислоты.</p>
                                <p>Полифосфат аммония применяется в различных областях
                                    промышленности:
                                    автомобильной, лакокрасочной, химической, мебельной и даже пищевой. Наиболее широко
                                    он
                                    применяется в качестве пламя-замедляющей добавки, т. е. является эффективным
                                    антипиреном
                                    и
                                    применяется при производстве огнезащитных красок, лаков, пропиток, мастик,
                                    пластиков,
                                    оболочек
                                    электрических кабелей и т. д. </p>
                                <p>Одним из основных достоинств полифосфата аммония как антипирена, является его
                                    экологическая безопасность и не токсичность. Полифосфат аммония не содержит
                                    галогены,
                                    являющиеся
                                    очень ядовитыми и токсичными веществами для людей, животных и окружающей
                                    природы.</p>
                                <More
                                    href='/info/polyphosphate'
                                    caption='Читать полностью'
                                />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.text}>
                                <p>Доставка может осуществляться, как за счет компании ООО «Меламин Трейд», так и на
                                    условиях
                                    самовывоза со склада компании, расположенного по адресу: г. Щелково, ул. Заводская,
                                    д.2-65,
                                    строение 1, помещение 2, офис 1</p>
                                <p>Основное время работы склада: c 9-00 до 18-00 в рабочие дни. Самовывоз в другое время
                                    можно
                                    согласовывать отдельно.</p>
                                <More
                                    href='/contacts'
                                    caption='Посмотреть на карте'
                                />
                                <p>Оплата производится по <Link
                                    className={styles.link}
                                    to='/payment'
                                >реквизитам</Link></p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.text}>
                                <p>Мешки полифосфата аммония хранят в закрытых помещениях, предохраняя от действия
                                    прямых
                                    солнечных
                                    лучей и влаги. Гарантийный срок хранения сухого состава — 2 года с даты
                                    изготовления.</p>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={`${styles.text} ${styles.certificationContainer}`}>
                                <div className={styles.certificateWrapper}>
                                    <Certificate
                                        preview={pfaKylinPreview}
                                        name='SHIFANG CHANGFENG CHEMICAL, 201140805'
                                        file={pfaKylin05}
                                    />
                                    <Certificate
                                        preview={pfaKylinPreview}
                                        name='SHIFANG CHANGFENG CHEMICAL, 201140806'
                                        file={pfaKylin06}
                                    />
                                    <Certificate
                                        preview={pfaKylinPreview}
                                        name='SHIFANG CHANGFENG CHEMICAL, 201140807'
                                        file={pfaKylin07}
                                    />
                                    <Certificate
                                        preview={pfaKylinPreview}
                                        name='SHIFANG CHANGFENG CHEMICAL, 201140809'
                                        file={pfaKylin09}
                                    />
                                </div>
                                <p className={styles.certified}>Вся поставляемая продукция сертифицирована в
                                    соответствии с
                                    действующим законодательством
                                    Российской Федерации.</p>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </article>
            <Seo
                title='Полифосфат аммония'
                description='Полифосфат аммония оптом в России. Доставка, самовывоз. Полифосфат аммония – это неорганическая соль фосфорной кислоты.'
                keywords='полифосфат аммония'
                language='ru'
                siteName='ООО «Меламин Трейд»'
            />
        </>
    )
}
